import React from "react"
import {Link} from "gatsby"
import { OutboundLink } from 'gatsby-plugin-google-analytics'

import styles from "./navigation.module.css"

function Github() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        x="0"
        y="0"
        enableBackground="new 0 0 24 23.41"
        viewBox="0 0 24 23.41"
      >
        <path d="M12 0C5.37 0 0 5.37 0 12c0 5.3 3.44 9.8 8.2 11.38.6.11.82-.26.82-.58 0-.28-.01-1.04-.02-2.04-3.34.72-4.04-1.61-4.04-1.61-.55-1.38-1.33-1.75-1.33-1.75-1.09-.74.08-.73.08-.73 1.2.08 1.84 1.24 1.84 1.24 1.07 1.83 2.81 1.31 3.49 1 .11-.78.42-1.31.76-1.6-2.66-.3-5.47-1.33-5.47-5.93 0-1.31.47-2.38 1.24-3.22-.14-.3-.54-1.52.11-3.18 0 0 1.01-.32 3.3 1.23.96-.27 1.98-.4 3-.41 1.02.01 2.04.14 3 .41 2.28-1.55 3.29-1.23 3.29-1.23.65 1.65.24 2.87.12 3.18.76.84 1.23 1.91 1.23 3.22 0 4.61-2.81 5.63-5.48 5.92.42.36.81 1.1.81 2.22 0 1.61-.02 2.9-.02 3.29 0 .31.21.69.82.57C20.57 21.79 24 17.29 24 12c0-6.63-5.37-12-12-12" />
      </svg>
    );
  }

  function Linkedin() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        x="0"
        y="0"
        enableBackground="new 0 0 24 24"
        viewBox="0 0 24 24"
      >
        <path d="M20.45 20.45H16.9v-5.57c0-1.33-.03-3.04-1.85-3.04-1.85 0-2.14 1.44-2.14 2.94v5.67H9.35V9h3.41v1.56h.05c.48-.9 1.64-1.85 3.37-1.85 3.6 0 4.27 2.37 4.27 5.45v6.29zM5.34 7.43c-1.14 0-2.06-.93-2.06-2.07 0-1.14.92-2.06 2.06-2.06 1.14 0 2.06.92 2.06 2.06 0 1.15-.92 2.07-2.06 2.07zm1.78 13.02H3.56V9h3.56v11.45zM22.23 0H1.77C.79 0 0 .77 0 1.73v20.54C0 23.23.79 24 1.77 24h20.45c.98 0 1.78-.77 1.78-1.73V1.73C24 .77 23.2 0 22.23 0z" />
      </svg>
    );
  }


export default() => (
    <>
    <header className={styles.header}>
        <h1><Link to={`/`}>
            A Slacker’s Guide to Programming
            </Link>
        </h1>
        <OutboundLink href="https://github.com/papagoat" alt="Papagoat's Github Page">
            <Github />
        </OutboundLink>
        <OutboundLink href="http://www.linkedin.com/in/terencelucasyap" alt="Terence Lucas Yap LinkedIn Profile">
            <Linkedin />
        </OutboundLink>
    </header>
    </>
)