import React from "react"
import styles from "./footer.module.css"

class Footer extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        currentYear: [],
      }
    }
    // note: this is a simplified example without error handling, authentication, etc.
    async componentDidMount() {
      this.setState({
        currentYear: new Date().getFullYear(),
      })
    }
    render() {
      const { currentYear } = this.state
      return (
        <footer className={styles.footer}>
            <h4>Copyright &copy; Terence Lucas Yap {currentYear}</h4>
            <p>Powered by Gatsby JS</p>
        </footer>
      )
    }
  }
  export default Footer